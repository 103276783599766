
class ImCamera {
	constructor() {
		this.stream = null;
	}
}

ImCamera.prototype.isEnable = function() {
	return !!navigator && !!navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia;
}

ImCamera.prototype.openVideo = function() {
	return new Promise((resolve, reject) => {
		if(this.stream){
			this.close()
		}
		let constraints = {
			video: {
				with: window.screen.width,
				height: window.screen.height
			},
			audio: {
				echoCancellation: true, //音频开启回音消除
				noiseSuppression: true // 开启降噪
			}
		}
		console.log("getUserMedia")
		navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
			console.log("摄像头打开")
			this.stream = stream;
			
			//通过标签播放视频var deskVideo = document.querySelect("video/deskVideo");
			 //deskVideo.srcObject = stream;
			resolve(stream);
		}).catch((e) => {
			console.log(e)
			console.log("摄像头未能正常打开")
			reject({
				code: 0,
				message: "摄像头未能正常打开"
			})
		})
	})
}


ImCamera.prototype.openAudio = function() {
	return new Promise((resolve, reject) => {
		let constraints = {
			video: false,
			audio: {
				echoCancellation: true, //音频开启回音消除
				noiseSuppression: true // 开启降噪
			}
		}
		
		navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
			this.stream = stream;
			resolve(stream);
		}).catch(() => {
			console.log("麦克风未能正常打开")
			reject({
				code: 0,
				message: "麦克风未能正常打开"
			})
		})

	})
}

ImCamera.prototype.close = function() {
	// 停止流
	if (this.stream) {
		this.stream.getTracks().forEach((track) => {
			track.stop();
		});
	}
}
//---------------------桌面共享功能---------------------
//
 // // 抓取桌面
 //    function shareDesktop() {
 //        // 只有在 PC 下才能抓取桌面
 //        if (IsPC()) {
 //            // 开始捕获桌面数据
 //            navigator.mediaDevices.getDisplayMedia({ video: true })
 //                .then(getDeskStream)
 //                .catch(handleError);
 //            return true;
 //        }
 //        return false;
 //    }

 //    // 得到桌面数据流
 //    function getDeskStream(stream) {
 //        localStream = stream;
		
 //    }

 //    // 判断是否是PC
 //    function IsPC() {
 //        var userAgentInfo = navigator.userAgent;
 //        var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
 //        var flag = true;
 //        for (var v = 0; v < Agents.length; v++) {
 //            if (userAgentInfo.indexOf(Agents[v]) > 0) {
 //                flag = false;
 //                break;
 //            }
 //        }
 //        return flag;
 //    }
	// //------------------------------------------------------------------------
	// async function getDisplayStream () {
	//   return await navigator.mediaDevices.getDisplayMedia({
	//     video: true,
	//     audio: false
	//   })
	// }
	
	// async function createConnection () {
	//   const stream = await getDisplayStream()
	//   const connection = new RTCPeerConnection()
	//   connection.addStream(stream)
	//   connection.createOffer().then(offer => {
	//     connection.setLocalDescription(offer)
	//     console.log('offer sdp', offer.sdp)
	//     // 建立信令传输，例如websocket
	//     // webSocket.send(JSON.stringify({offer: offer.sdp}))
	//   })
	//   connection.ontrack = (e) => {
	//     console.log('on track', e.streams[0])
	//   }
	// }
//--------------------------------------------------------------------------------	
	
export default ImCamera;