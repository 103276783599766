import http from './httpRequest.js'

class RtcPrivateApi {
}
//呼叫视频通话
RtcPrivateApi.prototype.call = function(uid, mode, offer) {
	return http({
		url: `/webrtc/private/call?uid=${uid}&mode=${mode}`,
		method: 'post',
		data: JSON.stringify(offer),
		headers: {
			'Content-Type': 'application/json; charset=utf-8'
			//'Access-Control-Allow-Origin','*'
		}
	})
}
//接受视频通话"
RtcPrivateApi.prototype.accept = function(uid, answer) {
	return http({
		url: `/webrtc/private/accept?uid=${uid}`,
		method: 'post',
		data: JSON.stringify(answer),
		headers: {
			'Content-Type': 'application/json; charset=utf-8'
		}
	})
}

//"挂断"
RtcPrivateApi.prototype.handup = function(uid) {
	return http({
		url: `/webrtc/private/handup?uid=${uid}`,
		method: 'post'
	})
}
//"取消呼叫"
RtcPrivateApi.prototype.cancel = function(uid) {
	return http({
		url: `/webrtc/private/cancel?uid=${uid}`,
		method: 'post'
	})
}
//"拒绝视频通话"
RtcPrivateApi.prototype.reject = function(uid) {
	return http({
		url: `/webrtc/private/reject?uid=${uid}`,
		method: 'post'
	})
}
//"呼叫失败"
RtcPrivateApi.prototype.failed = function(uid, reason) {
	return http({
		url: `/webrtc/private/failed?uid=${uid}&reason=${reason}`,
		method: 'post'
	})
}
//"同步candidate"
RtcPrivateApi.prototype.sendCandidate = function(uid, candidate) {
	return http({
		url: `/webrtc/private/candidate?uid=${uid}`,
		method: 'post',
		data: JSON.stringify(candidate),
		headers: {
			'Content-Type': 'application/json; charset=utf-8'
		}
	});
}
//"获取通话信息"
RtcPrivateApi.prototype.heartbeat = function(uid) {
	return http({
		url: `/webrtc/private/heartbeat?uid=${uid}`,
		method: 'post'
	})
}

export default RtcPrivateApi;